import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import {
  Helmet,
  Banner,
  Layout,
  Theme,
  media,
  TickListItem,
  Interfaces,
  ReadMoreLink,
  ContactUsPhoneLink,
  Section,
  Container,
  Row,
  Box,
  Heading,
  Panel,
  routesObject
} from "@life-without-barriers/react-components"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { Site } from "@life-without-barriers/gatsby-common"
import Form, {
  FormFields,
  FieldsProps,
  DataFormatters
} from "@life-without-barriers/shared-contact-form"

const { Name, ContactMethodToggleField, Postcode } = FormFields

const { agedCareThemeFull } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
    ourStaffImage: IGatsbyImageData
    scheduleOfFees: Interfaces.FileProps
  }
}

const StyledList = styled.ul`
  ${media.notSmall`
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  `}
`

const EmailWrap = styled.div`
  word-wrap: break-word;
`

const PageForm = (
  <Form
    fieldFactory={(fieldsProps: FieldsProps) => [
      <Name key="Name" {...fieldsProps} />,
      <Postcode key="Postcode" {...fieldsProps} />,
      <ContactMethodToggleField
        key="ContactMethodToggleField"
        initialContactMethod="phone"
        {...fieldsProps}
      />
    ]}
    formId="desktop-aged-care-contact-form"
    formKind="aged-care"
    formTitle="Contact Us"
    initialValues={{
      contactMethod: "phone",
      enquiryType: "COVID-19 Emergency Aged Care contact"
    }}
    formDataFormatter={DataFormatters.nestContactDetailsFormatter}
  />
)

const EmergencyAgedCareServicePage = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Emergency aged care services | ${siteMetadata.title}`}
        description="Our services can support you to maintain your freedom and independence at home."
        image={bannerImage}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        themeColor={agedCareThemeFull.medium}
      />
      <Layout
        theme={agedCareThemeFull}
        fixedCTAPhoneNumber="1800313117"
        fixedCTAContact={PageForm}
      >
        <Banner
          title="Emergency aged care services"
          image={bannerImage}
          breadcrumbs={[
            routesObject.home,
            routesObject.service,
            {
              to: "/services/aged-care/",
              text: "Aged care services",
              title: "Aged care services"
            }
          ]}
        />
        <Container>
          <Row className="flex-wrap">
            <Box className="w-100 w-seven-twelfths-l layout-gap">
              <Heading size={2}>
                COVID-19 Emergency Support for Older Australians
              </Heading>
              <p>
                Are you over 65 years of age or Aboriginal or Torres Strait
                Islander and over 50 and need some urgent assistance during the
                COVID-19 pandemic? Life Without Barriers can help older
                Australians access emergency support services through the
                Australian Government’s Commonwealth Home Support Programme
                (CHSP).
              </p>
              <p>
                Should you have an urgent need for aged care support, Life
                Without Barriers will complete a referral to My Aged Care for
                you and commence services as soon as possible.
              </p>
            </Box>
            <Box className="w-100 w-third-l pt4 pt0-l">
              <Panel background="bg-lwb-white" panelLine shadow>
                <Heading
                  topMargin="none"
                  size={3}
                  className="color-lwb-theme-medium"
                >
                  For more information
                </Heading>
                <div>
                  <p>
                    Call our team today or fill out the online form. We will
                    help you access this emergency aged care support.
                  </p>
                </div>
                <ContactUsPhoneLink
                  marginClasses="w-100 mt4"
                  height="26"
                  phoneNumber="1800313117"
                />
                <div className="pl5">
                  <div>Monday to Friday</div>
                  <div>8am to 8pm (AEST)</div>
                  <EmailWrap className="pt3">
                    Out of hours please email{" "}
                    <a
                      className="black fw5"
                      href="mailto:agedcareenquiries@lwb.org.au"
                    >
                      agedcareenquiries@lwb.org.au
                    </a>
                  </EmailWrap>
                </div>
              </Panel>
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row className="flex-wrap relative">
              <Box className="w-100 w-seven-twelfths-l layout-gap">
                <Heading size={2}>Types of support available:</Heading>
                <StyledList className="mt4 pl1 mb0">
                  {[
                    "On-line group or individual activities",
                    "Do your grocery shopping",
                    "Assistance with Personal Monitoring Technology to keep you safe",
                    "Help connecting with family and friends using technology",
                    "Help around the home",
                    "Pick up and drop off your medication",
                    "Social chat, coffee or a walk",
                    "Transport to appointments",
                    "Other supports tailored to you"
                  ].map((text, i) => (
                    <TickListItem
                      key={i}
                      text={text}
                      className={i === 0 ? "w-90-ns" : "mt4 w-90-ns"}
                    />
                  ))}
                </StyledList>
              </Box>
              <Box className="w-100 w-34-l absolute-l top-0-l right-0-l z-5 mt4 mt0-l">
                {PageForm}
              </Box>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row className="pb5-l">
            <Box className="w-100 w-seven-twelfths-l">
              <Heading size={3} topMargin="none">
                More information is available in the Department of Health’s My
                Aged Care Fact Sheet
              </Heading>
              <ReadMoreLink
                text="Download the My Aged Care Fact Sheet"
                href={
                  "https://www.health.gov.au/sites/default/files/documents/2019/12/direct-to-service-referrals-for-chsp-providers-fact-sheet.pdf"
                }
                className="mt3"
              />
            </Box>
          </Row>
        </Container>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerImage: file(
      relativePath: { regex: "/img-emergency-aged-care.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 90)
      }
    }
  }
`

export default EmergencyAgedCareServicePage
